type State = 'commandPalette'|'addTransaction'|'addArea'|'addCategory'

export const isCommandOpen = ref(false)
export const isAddTransactionOpen = ref(false)
export const isAddAreaOpen = ref(false)
export const isAddCategoryOpen = ref(false)
export const addCategoryData = ref({})

export const setState = (state: State = 'commandPalette', type: 'toggle'|'on'|'off' = 'toggle', data: object = {}) => {
  let ref = isCommandOpen

  if(state === 'addTransaction') {
    ref = isAddTransactionOpen
  } else if(state === 'addArea') {
    ref = isAddAreaOpen
  } else if(state === 'addCategory') {
    ref = isAddCategoryOpen
    if(data) {
      addCategoryData.value = data
    } else {
      addCategoryData.value = {}
    }
  }

  if (type === 'toggle') {
    ref.value = !ref.value
  } else if (type === 'on') {
    isAddAreaOpen.value = false
    isAddCategoryOpen.value = false
    isAddTransactionOpen.value = false
    isCommandOpen.value = false
    ref.value = true
  } else if (type === 'off') {
    ref.value = false
  }
}