<script setup lang="ts">
import type { PropType } from 'vue'
import {getCategoryProgressText, getCategoryTargetText} from '~/composables/BudgetEngine'
import { formatAmountWithCurrency } from '~/composables/AmountModifier'
import {formatISO, isToday} from 'date-fns'
const appConfig = useAppConfig()
const { $areaStore } = useNuxtApp()

const props = defineProps({
  category: {
    type: Object as PropType<Category>,
    required: true
  },
  currency: {
    type: Object as PropType<Currency>,
    required: true
  },
  editing: {
    type: Boolean,
    default: false
  },
  newCategory: {
    type: Boolean,
    default: false
  }
})

const emits = defineEmits(['editorClosed', 'categoryUpdated'])

type GoalTypeOption = {
  id: 'nt' | 'spending' | 'saving';
  icon: string;
  title: string;
  subtitle: string;
};

const editing = ref(props.editing)

const goalTypeOptions: GoalTypeOption[] = [
  {
    id: 'nt',
    icon: 'fa-light fa-money-bill-simple',
    title: 'No target',
    subtitle: 'Just keep the balance above zero'
  },
  {
    id: 'spending',
    icon: 'fa-light fa-cart-shopping',
    title: 'For spending',
    subtitle: 'Assigned funds must reach the target, but can be spent freely'
  },
  {
    id: 'saving',
    icon: 'fa-light fa-piggy-bank',
    title: 'For saving',
    subtitle: 'Assigned funds must reach the target, and spent funds must be replenished'
  }
]

const goalTypeSelected = ref(goalTypeOptions[0] as GoalTypeOption)
const goalTarget = ref(props.category.goal.target)

const goalFrequencies = [{
  key: 'weekly',
  label: 'Weekly'
}, {
  key: 'monthly',
  label: 'Monthly'
}, {
  key: 'yearly',
  label: 'Yearly'
}, {
  key: 'once',
  label: 'Once'
}]

const weekdays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
const frequencies = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]

const weeklyFrequencies = [
	 {
    value: 1,
    title: 'Week',
  },
	 {
    value: 2,
    title: '2 weeks',
  },
	 {
    value: 4,
    title: '4 weeks',
  }
]

const monthlyFrequencies = frequencies.map(value => ({
  value,
  title: `${value > 1 ? value + ' months' : 'Month'}`
}))

const yearlyFrequencies = frequencies.map(value => ({
  value,
  title: `${value > 1 ? value + ' years' : 'Year'}`
}))

const selectedWeeklyFrequency = ref(1); const selectedMonthlyFrequency = ref(1); const selectedYearlyFrequency = ref(1)

const daysInMonth = [
  { value: 'last', title: 'Last day of month' },
  ...Array.from({ length: 30 }, (_, i) => {
    const value = i + 1
    let suffix = 'th'
    if (value % 10 === 1 && value !== 11) { suffix = 'st' }
    if (value % 10 === 2 && value !== 12) { suffix = 'nd' }
    if (value % 10 === 3 && value !== 13) { suffix = 'rd' }
    return { value, title: `${value}${suffix}` }
  })
]

let selectedGoalFrequency = 0
const selectedWeekday = ref(weekdays[0])
const selectedDayInMonth: any = ref('last')

const yearlyDate = ref()
const onceDate = ref()
const anchorDate = ref()

setValuesToProp()

const yearlyDateLabel = computed(() => yearlyDate.value.toLocaleDateString('en-us', { year: 'numeric', month: 'long', day: 'numeric' }))
const onceDateLabel = computed(() => onceDate.value.toLocaleDateString('en-us', { year: 'numeric', month: 'long', day: 'numeric' }))
const anchorDateLabel = computed(() => {
  if (isToday(anchorDate.value)) { return 'Starting from today' }
  return 'Starting from ' + anchorDate.value.toLocaleDateString('en-us', { year: 'numeric', month: 'long', day: 'numeric' })
})

function submitCategory() {
  if(!props.newCategory) {
    if(goalTypeSelected.value.id !== 'nt') {
      $areaStore.editCategoryGoal(props.category.id, {
        ...props.category?.goal,
        type: goalTypeSelected.value.id,
        target: goalTarget.value,
        frequency: goalFrequencies[selectedGoalFrequency].key,
        due_by: goalFrequencies[selectedGoalFrequency].key === 'weekly' ? weekdays.indexOf(selectedWeekday.value) + 1 : goalFrequencies[selectedGoalFrequency].key === 'monthly' ? selectedDayInMonth.value : goalFrequencies[selectedGoalFrequency].key === 'yearly' ? yearlyDate.value : onceDate.value,
        repeat_every: goalFrequencies[selectedGoalFrequency].key === 'weekly' ? selectedWeeklyFrequency.value : goalFrequencies[selectedGoalFrequency].key === 'monthly' ? selectedMonthlyFrequency.value : goalFrequencies[selectedGoalFrequency].key === 'yearly' ? selectedYearlyFrequency.value : 1,
        repeat_anchor: formatISO(anchorDate.value, { representation: 'date' })
      } as Goal)
    } else {
      $areaStore.editCategoryGoal(props.category.id, {
        ...props.category?.goal,
        type: goalTypeSelected.value.id,
        target: 0,
        frequency: 'monthly',
        due_by: 'last',
        repeat_every: 1,
        repeat_anchor: null
      } as Goal)
    }
  } else {
    emits('categoryUpdated', {
      ...props.category?.goal,
      type: goalTypeSelected.value.id,
      target: goalTarget.value,
      frequency: goalFrequencies[selectedGoalFrequency].key,
      due_by: goalFrequencies[selectedGoalFrequency].key === 'weekly' ? weekdays.indexOf(selectedWeekday.value) + 1 : goalFrequencies[selectedGoalFrequency].key === 'monthly' ? selectedDayInMonth.value : goalFrequencies[selectedGoalFrequency].key === 'yearly' ? yearlyDate.value : onceDate.value,
      repeat_every: goalFrequencies[selectedGoalFrequency].key === 'weekly' ? selectedWeeklyFrequency.value : goalFrequencies[selectedGoalFrequency].key === 'monthly' ? selectedMonthlyFrequency.value : goalFrequencies[selectedGoalFrequency].key === 'yearly' ? selectedYearlyFrequency.value : 1,
      repeat_anchor: formatISO(anchorDate.value, { representation: 'date' })
    })
  }

  editing.value = false
}

function setValuesToProp() {
 if (props.category.goal.type === 'spending') { goalTypeSelected.value = goalTypeOptions[1] as GoalTypeOption }
 if (props.category.goal.type === 'saving') { goalTypeSelected.value = goalTypeOptions[2] as GoalTypeOption }

 goalTarget.value = props.category.goal.target
	if (props.category.goal.frequency === 'weekly') {
		selectedWeekday.value = weekdays[props.category.goal.due_by as number - 1 || 0]
	} else if (props.category.goal.frequency === 'monthly') {
	  selectedGoalFrequency = 1
		selectedDayInMonth.value = props.category.goal.due_by || 'last'
	} else if (props.category.goal.frequency === 'yearly') {
	  selectedGoalFrequency = 2
	} else {
    selectedGoalFrequency = 3
  }

  if (props.category.goal.frequency && ['yearly', 'once'].includes(props.category.goal.frequency)) {
    yearlyDate.value = new Date(props.category.goal.due_by as Date)
    onceDate.value = new Date(props.category.goal.due_by as Date)
  } else {
    yearlyDate.value = new Date()
    onceDate.value = new Date()
  }

  if(props.category.goal.repeat_anchor) {
    anchorDate.value = new Date(props.category?.goal.repeat_anchor)
  } else {
    anchorDate.value = new Date()
  }

  selectedWeeklyFrequency.value = [1, 2, 4].includes(props.category?.goal.repeat_every) ? props.category?.goal.repeat_every : 1 || 1
  selectedMonthlyFrequency.value = props.category?.goal.repeat_every || 1
  selectedYearlyFrequency.value = props.category?.goal.repeat_every || 1
}

function cancel() {
  if(!props.newCategory) {
    setValuesToProp()
    editing.value = false
    emits('editorClosed')
  }
}

function onFrequencyChange (index: number) {
	selectedGoalFrequency = index
}

const saveDisabled = computed(() => {
  return goalTypeSelected.value.id !== 'nt' && goalTarget.value <= 0;
})
</script>

<template>
  <UCard v-if="!editing" :ui="{ 'base': 'w-full', divide: 'divide-none', header: { padding: 'px-0 sm:px-0 py-0 sm:py-0' }, body: { padding: ' py-4 sm:py-5' },}">
    <template #header>
      <div class="flex justify-between items-center px-5 py-4">
        <div class="">
          <div class="flex flex-row items-center">
            <p class="font-medium flex-inline items-center">
              <span v-if="category.goal.type !== 'nt'">{{ category.goal.type.charAt(0).toUpperCase() + category.goal.type.slice(1) }} goal</span>
              <span v-else>No target</span>
            </p>
          </div>

          <p v-if="category.goal.type !== 'nt'" class="text-xs mt-1 text-gray-500 mr-5">
            {{ getCategoryTargetText(category, currency) }}
          </p>
        </div>

        <UButton
          class="whitespace-nowrap max-w-full"
          variant="link"
          color="primary"
          @click="editing = true"
        >
          Edit
        </UButton>
      </div>
      <BudgetCategoryProgress
          :progresses="[
              {
                color: $areaStore.getProgressComplete((category.goal.type !== 'nt' ? category.goal.progress : category.budgeted / category.spent) as number) ? 'green' : 'yellow',
                value: Math.max(Math.min((category.goal.type !== 'nt' ? category.goal.overall_progress*100 : (category.budgeted / category.spent)*100), 100), 0),
                visible: true,
                index: 1
              }
          ]"
      />
    </template>

    <div class="flex flex-col gap-y-1">
      <div class="grid grid-cols-2 gap-4 px-0 w-full">
        <dt class="text-sm leading-6 text-gray-500">
          Total needed
        </dt>
        <dd class="mt-1 text-sm font-medium leading-6 text-gray-950 dark:text-gray-50 text-right">
          <AnimatedNumber :value="category.goal.target" :currency="currency" />
        </dd>
      </div>

      <div v-if="category.goal.type === 'saving'" class="grid grid-cols-2 gap-4 px-0 w-full">
        <dt class="text-sm leading-6 text-gray-500">
          Spent this month
        </dt>
        <dd class="mt-1 text-sm font-medium leading-6 text-gray-950 dark:text-gray-50 text-right">
          <AnimatedNumber :value="category.spent" :currency="currency" />
        </dd>
      </div>

      <div class="grid grid-cols-2 gap-4 px-0 w-full">
        <dt class="text-sm leading-6 text-gray-500">
          Total assigned
        </dt>
        <dd class="mt-1 text-sm font-medium leading-6 text-gray-950 dark:text-gray-50 text-right">
          <AnimatedNumber :value="category.goal.overall_funded" :currency="currency" />
        </dd>
      </div>

      <div class="grid grid-cols-2 gap-4 px-0 w-full">
        <dt class="text-sm leading-6 text-gray-500">
          Total remaining
        </dt>
        <dd class="mt-1 text-sm font-medium leading-6 text-gray-950 dark:text-gray-50 text-right">
          <AnimatedNumber :value="category.goal.type !== 'saving' ?
                category.goal.target - category.goal.overall_funded :
                category.goal.target - category.goal.overall_funded + category.spent" :currency="currency" />
        </dd>
      </div>
    </div>

    <UAlert
        v-if="category.goal.type !== 'nt'"
        class="mt-5 bg-green-100"
        :class="category.goal.progress === 1 ? 'bg-green-100' : 'bg-yellow-100'"
        :color="category.goal.progress === 1 ? 'green' : 'yellow'"
        variant="soft"
        :ui="{ title: 'text-center' }"
        :title="getCategoryProgressText(category, currency, false)"
    />
  </UCard>
  <div v-else>
    <UCard :ui="{ base: 'overflow-visible', divide: 'divide-none', header: { padding: 'px-0 sm:px-0 py-0 sm:py-0' }, body: { padding: 'px-4 sm:px-5 pb-4 sm:pb-5 pt-2 sm:pt-3' } }">
      <div class="flex flex-col gap-y-5">
        <USelectMenu
          v-model="goalTypeSelected"
          :options="goalTypeOptions"
        >
          <template #default>
            <div class="flex flex-col w-full cursor-default">
              <p class="text-sm" :class="appConfig.ui.formGroup.label.base">
                Goal type
              </p>
              <UButton size="lg" color="white" class="flex-1 mt-1 justify-between" :ui="{ base: 'cursor-default', font: 'font-normal' }">
                {{ goalTypeSelected.title }}
                <fa icon="fa-regular fa-chevron-down" class="w-5 h-5" />
              </UButton>
            </div>
          </template>

          <template #label>
            {{ goalTypeSelected.title }}
          </template>

          <template #option="{ option: goalType, active }">
            <div class="flex flex-row items-center gap-x-4 py-2 px-1">
              <fa :icon="goalType.icon" :class="[active ? appConfig.ui.selectMenu.option.icon.active : appConfig.ui.selectMenu.option.icon.inactive, appConfig.ui.selectMenu.option.icon.base, 'text-lg h-6 w-6']" aria-hidden="true" />
              <div class="flex flex-col">
                <span class="">
                  {{ goalType.title }}
                </span>
                <span class="text-xs text-gray-400 dark:text-gray-500">
                  {{ goalType.subtitle }}
                </span>
              </div>
            </div>
          </template>
        </USelectMenu>

        <div v-if="goalTypeSelected.id !== 'nt'">
          <UFormGroup
            label="Amount"
            name="amount"
          >
            <UInput
              v-model="goalTarget"
              inputmode="decimal"
              @keydown.enter="submitCategory"
              v-amount
              :ui="{ 'base': 'w-full', input: 'text-sm' }"
              placeholder="Target amount"
            >
              <template
                v-if="currency.symbol_placement === 'before'"
                #leading
              >
                <span class="opacity-100">{{ currency.symbol }}</span>
              </template>
              <template
                v-else-if="currency.symbol_placement === 'after'"
                #trailing
              >
                <span class="opacity-100">{{ currency.symbol }}</span>
              </template>
            </UInput>
          </UFormGroup>
        </div>
        <div v-if="goalTypeSelected.id !== 'nt'">
          <UCard class="mt-1" :ui="{ base: 'overflow-visible', body: { padding: 'px-0 sm:px-0 !pt-0 !pb-5' }, background: appConfig.ui.tabs.list.background, shadow: 'shadow-none' }">
            <UTabs :items="goalFrequencies" :default-index="selectedGoalFrequency as number" @change="onFrequencyChange" class="w-full">
              <template #item="{ item }">
                <div class="pt-2 px-5">
                  <div v-if="item.key === 'weekly'" class="flex flex-col gap-2">
                    <div class="flex flex-col gap-5 sm:flex-row">
                      <UFormGroup label="Due by" name="day-weekly" class="w-full">
                        <USelect
                            v-model="selectedWeekday"
                            :options="weekdays"
                        />
                      </UFormGroup>
                      <UFormGroup label="Every" name="frequency-weekly" class="w-full">
                        <USelect
                            v-model="selectedWeeklyFrequency"
                            :options="weeklyFrequencies"
                            option-attribute="title"
                        />
                      </UFormGroup>
                    </div>
                    <div>
                      <UPopover :ui="{}" :popper="{ strategy: 'absolute', placement: 'bottom-start' }">
                        <UButton class="-ml-1.5" size="xs" variant="link" color="gray">
                          {{ anchorDateLabel }}
                        </UButton>
                        <template #panel="{ close }">
                          <DatePicker v-model="anchorDate" @close="close" />
                        </template>
                      </UPopover>
                    </div>
                  </div>
                  <div v-if="item.key === 'monthly'" class="flex flex-col gap-2">
                    <div class="flex flex-col gap-5 sm:flex-row">
                      <UFormGroup label="Due by" name="day-monthly" class="w-full">
                        <USelect
                            v-model="selectedDayInMonth"
                            :options="daysInMonth"
                            option-attribute="title"
                        />
                      </UFormGroup>
                      <UFormGroup label="Every" name="frequency-monthly" class="w-full">
                        <USelect
                            v-model="selectedMonthlyFrequency"
                            :options="monthlyFrequencies"
                            option-attribute="title"
                        />
                      </UFormGroup>
                    </div>
                    <div>
                      <UPopover :ui="{}" :popper="{ strategy: 'absolute', placement: 'bottom-start' }">
                        <UButton class="-ml-1.5" size="xs" variant="link" color="gray">
                          {{ anchorDateLabel }}
                        </UButton>
                        <template #panel="{ close }">
                          <DatePicker v-model="anchorDate" @close="close" />
                        </template>
                      </UPopover>
                    </div>
                  </div>
                  <div v-else-if="item.key === 'yearly'" class="flex flex-col gap-5 sm:flex-row">
                    <UFormGroup label="First due" name="day-yearly" class="w-full">
                      <UPopover :ui="{}" :popper="{ strategy: 'absolute', placement: 'bottom-start' }">
                        <UInput
                          :model-value="yearlyDateLabel"
                          class="w-full"
                          :ui="{ 'base': 'w-full', input: 'text-sm' }"
                          placeholder="Start"
                        />
                        <template #panel="{ close }">
                          <DatePicker v-model="yearlyDate" @close="close" />
                        </template>
                      </UPopover>
                    </UFormGroup>
                    <UFormGroup label="Repeat every" name="frequency-yearly" class="w-full">
                      <USelect
                        v-model="selectedYearlyFrequency"
                        :options="yearlyFrequencies"
                        option-attribute="title"
                      />
                    </UFormGroup>
                  </div>
                  <div v-else-if="item.key === 'once'" class="flex flex-row">
                    <UFormGroup label="Due by" name="day-once" class="w-full">
                      <UPopover :ui="{}" :popper="{ strategy: 'absolute', placement: 'bottom-start' }">
                        <UInput
                          :model-value="onceDateLabel"
                          class="w-full"
                          :ui="{ 'base': 'w-full', input: 'text-sm' }"
                          placeholder="Start"
                        />
                        <template #panel="{ close }">
                          <DatePicker v-model="onceDate" @close="close" />
                        </template>
                      </UPopover>
                    </UFormGroup>
                  </div>
                </div>
              </template>
            </UTabs>
          </UCard>
        </div>
      </div>

      <template #footer>
        <div class="flex flex-row items-center justify-between">
          <div class="flex gap-x-2">
            <UTooltip
                :popper="{ strategy: 'absolute' }"
                :prevent="!saveDisabled"
                text="Goal amount must be greater than zero"
            >
              <UButton :disabled="saveDisabled" @click="submitCategory" color="black">
                Save
              </UButton>
            </UTooltip>

            <UButton v-if="!newCategory" variant="ghost" color="white" @click="cancel">
              Cancel
            </UButton>
          </div>
        </div>
      </template>
    </UCard>
  </div>
</template>

<style scoped>

</style>
