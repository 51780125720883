<script setup lang="ts">
type Progress = {
  color: 'green' | 'yellow' | 'red' | 'gray' | 'white';
  value: number;
  index: number;
  visible: boolean;
}

const props = defineProps({
  progresses: {
    type: Array as PropType<Progress[]>,
    required: true
  },
  height: {
    type: String,
    default: 'h-[1.5px]'
  },
  bg: {
    type: String,
    default: 'bg-gray-100 dark:bg-gray-800'
  }
})

const appConfig = useAppConfig()

// transform progressor colors to tailwind classes
const progresses = computed(() => {
  return props.progresses.map(progress => {
    if(Number.isNaN(progress.value)) {
      progress.value = 0
    }
    switch(progress.color) {
      case 'green':
        return {...progress, color: 'bg-green-500'}
      case 'yellow':
        return {...progress, color: 'bg-yellow-500'}
      case 'red':
        return {...progress, color: 'bg-red-500'}
      case 'gray':
        return {...progress, color: 'bg-gray-500'}
      case 'white':
        return {...progress, color: 'bg-gray-400 dark:bg-gray-600'}
    }
  })
})
</script>

<template>
  <div :class="[height, bg]" class="z-1 relative rounded-full overflow-hidden">
    <div v-for="(progress) in progresses">
      <div
          class="h-full left-0 absolute border-white dark:border-[#151517] transition-[colors,width,opacity,transform] duration-500 ease-in-out"
          :class="[progress.color, [0, 100].includes(progress.value) ? '' : 'border-r-[4px]']"
          :style="{ width: progress.value + '%', zIndex: progress.index }"
      />
    </div>
  </div>
</template>

<style scoped>

</style>