<template>{{ formatAmount ? formatAmountWithCurrency(displayNumber, currency) : displayNumber }}</template>

<script setup lang="ts">
import type {PropType} from "vue";

const { $budgetStore } = useNuxtApp()

const props = defineProps({
  value: {
    type: Number,
    default: 0,
  },
  speed: {
    // smaller is faster
    type: Number,
    default: 8,
  },
  formatAmount: {
    type: Boolean,
    default: true,
  },
  currency: {
    type: Object as PropType<Currency>,
    default: null,
  }
});

const currency = ref(props.currency)

if(!props.currency) {
  currency.value = $budgetStore.budget.currency
}

const displayNumber = ref(props.value)
let interval: any = null

watch(
    () => props.value,
    (newVal) => {
      clearInterval(interval);

      if (newVal === displayNumber.value) {
        return;
      }

      interval = setInterval(() => {
        if (Math.floor(displayNumber.value) !== Math.floor(newVal)) {
          let change = (newVal - displayNumber.value) / props.speed
          change = change >= 0 ? Math.ceil(change) : Math.floor(change)
          displayNumber.value = displayNumber.value + change
        } else {
          displayNumber.value = newVal
          clearInterval(interval)
        }
      }, 20);
    }
);
</script>