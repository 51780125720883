<script setup lang="ts">
import { DatePicker as VCalendarDatePicker } from 'v-calendar'
import '~/assets/css/datepicker.css'
const props = defineProps({
  modelValue: {
    type: Date,
    default: null
  }
})
const emit = defineEmits(['update:model-value', 'close'])
const colorMode = useColorMode()
const isDark = computed(() => colorMode.value === 'dark')
const date = computed({
  get: () => props.modelValue,
  set: (value) => {
    emit('update:model-value', value)
    emit('close')
  }
})
const attrs = [
  {
    key: 'today',
    highlight: {
      fillMode: 'light',
      class: '!bg-gray-200 dark:!bg-gray-800'
    },
    dates: new Date()
  },
  {
    key: 'selected',
    highlight: {
      color: 'green',
      fillMode: 'light'
    },
    dates: date.value
  }
]
</script>
<template>
  <VCalendarDatePicker
    v-model="date"
    transparent
    borderless
    :attributes="attrs"
    :is-dark="isDark"
    color="green"
    title-position="left"
    trim-weeks
    :first-day-of-week="2"
  />
</template>
