type State = 'layoutPicker'|'categorySlider'|'deleteModal'

export const isLayoutPickerOpen = ref(false)
export const isCategorySliderOpen = ref(false)
export const isDeleteModalOpen = ref(false)
export const categorySliderData = ref({})
export const deleteModalData = ref({})


export const setBudgetState = (state: State = 'layoutPicker', type: 'toggle'|'on'|'off' = 'toggle', data: object = {}) => {
  let ref = isLayoutPickerOpen

  if(state === 'categorySlider') {
    ref = isCategorySliderOpen
    if(data) {
      categorySliderData.value = data
    }

  } else if(state === 'deleteModal') {
    ref = isDeleteModalOpen
    if(data) {
      console.log(data)
      deleteModalData.value = data
    }
  }

  if (type === 'toggle') {
    ref.value = !ref.value
  } else if (type === 'on') {
    isLayoutPickerOpen.value = false
    isCategorySliderOpen.value = false
    isDeleteModalOpen.value = false
    ref.value = true
  } else if (type === 'off') {
    ref.value = false
  }
}