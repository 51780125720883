<script setup>
// Define the function prop which expects a function
defineProps({
  items: {
    type: Array,
    default: () => []
  },
  mode: {
    type: String,
    default: 'click',
    validator: value => ['click', 'hover'].includes(value)
  },
  disabled: {
    type: Boolean,
    default: false
  },
  popper: {
    type: Object,
    default: () => ({})
  },
  openDelay: {
    type: Number,
    default: 0
  },
  closeDelay: {
    type: Number,
    default: 0
  },
  ui: {
    type: Object,
    default: () => ({})
  }
})

const appConfig = useAppConfig()
</script>

<template>
  <UDropdown
    :items="items"
    :ui="ui"
    :popper="popper"
    :disabled="disabled"
    :mode="mode"
    :open-delay="openDelay"
    :close-delay="closeDelay"
  >
    <slot />

    <template #item="{ item }">
      <fa
        v-if="item.icon"
        :icon="item.icon"
        :class="[appConfig.ui.dropdown.item.icon.base, appConfig.ui.dropdown.item.icon.inactive, item.iconClass]"
      />
      <UAvatar
        v-else-if="item.avatar"
        v-bind="{ size: appConfig.ui.dropdown.item.avatar.size, ...item.avatar }"
        :class="appConfig.ui.dropdown.item.avatar.base"
      />

      <span class="truncate">{{ item.label }}</span>

      <span
        v-if="item.shortcuts?.length"
        :class="appConfig.ui.dropdown.item.shortcuts"
      >
        <UKbd
          v-for="shortcut of item.shortcuts"
          :key="shortcut"
        >{{ shortcut }}</UKbd>
      </span>
    </template>
  </UDropdown>
</template>
